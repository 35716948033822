import React from "react";
import parse, { attributesToProps, domToReact } from "html-react-parser";

const opt = {
  replace: (node) => {
    if (node?.name === "img" && !node.attribs?.alt) {
      const isLink = node.parent.name === "a";
      return (
        <>
          <img {...attributesToProps(node.attribs)} alt={isLink ? "Link image" : ""} />
        </>
      );
    }
    if (
      node.name === "a" &&
      node.children?.every(
        (child) => child?.type === "text" && child?.data?.replace(/\s/g, "")?.length === 0
      )
    ) {
      return (
        <>
          <a {...attributesToProps(node.attribs)} aria-label={node.attribs?.id || ""}>
            {domToReact(node.children)}
          </a>
        </>
      );
    }
  },
};

export const handleContentAccessibility = (content) => {
  return parse(content, opt);
};
